import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import ModalExample from "./modal-example";

const VideoFrameSection = styled.div`
  position: relative;
  max-width: 38rem;
  margin: 0;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border: 1rem solid #f7f3ed;
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: -1;
    border-radius: 0.6rem;
  }
`;

const Video = styled.video`
  outline: none;
  display: block;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

const VideoButton = styled.button`
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-weight: 600;
  border: 0;
  padding: 0.5rem;
  border-radius: 0.2rem;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  z-index: 1;
  cursor: pointer;
  outline: 0;

  &:hover {
    background: #3182ce;
  }
`;

const cssVisible = keyframes`
  to { visibility: visible; }
`;

const VideoButtonsOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
  visibility: hidden;
  animation: ${cssVisible} 0s 0.5s forwards;
  transition: all 0.2s;
`;

const ExampleButton = styled.button`
  flex-shrink: 0;
  text-transform: uppercase;
  font-weight: 600;
  padding: 1rem;
  border-radius: 0.2rem;
  transition: all 0.2s;
  letter-spacing: 0.1rem;
  text-align: center;
  background: #45a85c;
  color: #fff;
  border: 0;
  margin: 0 0 1rem;
  cursor: pointer;
  outline: none;
  border: 2px solid #45a85c;
  position: relative;
  z-index: 1;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    border-color: #fff;
  }
`;
const PlayButton = styled.button`
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  outline: 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

export default class VideoFrame extends Component {
  state = {
    showVideoOverlay: false,
    showVideoButton: true,
    visible: false,
  };

  video;

  componentDidUpdate() {
    if (this.video) {
      this.video.addEventListener("pause", () => {
        this.setState({ showVideoOverlay: true });
      });

      this.video.addEventListener("timeupdate", () => {
        if (this.state.showVideoOverlay) {
          this.setState({ showVideoOverlay: false });
        }
      });
    }
  }

  videoHandler = () => {
    this.setState({ showVideoButton: false }, () => {
      this.video.controls = true;
      this.video.autoplay = false;
      this.video.loop = false;
      this.video.muted = false;
      this.video.volume = 1;
      this.video.currentTime = 0;
      this.video.textTracks[0].mode = "hidden";
    });
  };

  playVideo = () => {
    this.setState({ showVideoOverlay: false }, () => {
      this.video.play();
    });
  };

  render() {
    const { video, poster, videoControls, track } = this.props;
    const { showVideoButton, showVideoOverlay, visible } = this.state;

    return (
      <VideoFrameSection>
        {videoControls && showVideoButton && (
          <VideoOverlay onClick={this.videoHandler}>
            <VideoButton type="button" onClick={this.videoHandler}>
              Unmute
            </VideoButton>
          </VideoOverlay>
        )}

        {showVideoOverlay && (
          <VideoButtonsOverlay>
            <ExampleButton onClick={() => this.setState({ visible: true })}>
              See an example
            </ExampleButton>

            <PlayButton onClick={this.playVideo}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                fill="none"
              >
                <circle
                  cx="40"
                  cy="40"
                  r="40"
                  fill="#fff"
                  fillOpacity="0.8"
                ></circle>
                <path
                  fill="#181818"
                  d="M34.392 30l18 10.392-18 10.393V30z"
                ></path>
              </svg>
            </PlayButton>
            <div></div>
          </VideoButtonsOverlay>
        )}

        <Video
          src={video}
          poster={poster}
          ref={(video) => (this.video = video)}
          autoPlay
          playsInline
          loop
          muted
          controlsList="nodownload"
        >
          {track && (
            <track
              src={track}
              srcLang="en"
              label="English"
              kind="captions"
              default={true}
            />
          )}
        </Video>

        {visible && (
          <ModalExample
            visible={visible}
            closeHandler={() => this.setState({ visible: false })}
            gaTrackLabel={`Hero Video Video`}
          />
        )}
      </VideoFrameSection>
    );
  }
}
