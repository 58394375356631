import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import VideoFrame from "../video-frame";
import ModalExample from "../modal-example";

const VideoWrap = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  ${(props) =>
    props.positionStatic &&
    `
    padding: 0;
    height: auto;
  `}
`;

const VideoStyle = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  margin: 0 auto;
  box-shadow: 0 16px 26px -5px rgba(50, 50, 93, 0.1),
    0 8px 16px -8px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  background: #181818;
  outline: none;

  ${(props) =>
    props.positionStatic &&
    `
    position: relative;
  `}
`;

const Poster = styled.div`
  display: block;
  position: relative;
  box-shadow: 0 16px 26px -5px rgba(50, 50, 93, 0.1),
    0 8px 16px -8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  max-width: 832px;
  cursor: pointer;
  transition: 150ms;
  transition-timing-function: ease-in-out;
  border-radius: 1rem;

  > img {
    width: 100%;
    height: auto;
  }

  &:hover {
    svg {
      opacity: 0.8;
    }
  }

  img {
    display: block;
    border-radius: 4px;
  }

  svg {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }
`;

const cssVisible = keyframes`
  to { visibility: visible; }
`;

const VideoButtonsOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
  visibility: hidden;
  animation: ${cssVisible} 0s 0.5s forwards;
  transition: all 0.2s;
`;

const ExampleButton = styled.button`
  flex-shrink: 0;
  text-transform: uppercase;
  font-weight: 600;
  padding: 1rem;
  border-radius: 0.2rem;
  transition: all 0.2s;
  letter-spacing: 0.1rem;
  text-align: center;
  background: #45a85c;
  color: #fff;
  border: 0;
  margin: 0 0 1rem;
  cursor: pointer;
  outline: none;
  border: 2px solid #45a85c;
  position: relative;
  z-index: 1;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    border-color: #fff;
  }
`;
const PlayButton = styled.button`
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  outline: 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

class Video extends Component {
  state = {
    showVideo: this.props.playVideo || false,
    showVideoButton: true,
    showVideoOverlay: false,
    visible: false,
  };

  video;

  componentDidUpdate() {
    if (this.video) {
      this.video.addEventListener("pause", () => {
        this.setState({ showVideoOverlay: true });
      });

      this.video.addEventListener("timeupdate", () => {
        if (this.state.showVideoOverlay) {
          this.setState({ showVideoOverlay: false });
        }
      });
    }
  }

  playVideo = () => {
    this.setState({ showVideoOverlay: false }, () => {
      this.video.play();
    });
  };

  render() {
    const { url, poster, caption, autoplay, positionStatic } = this.props;
    const { showVideoOverlay, visible, showVideo } = this.state;

    return (
      <React.Fragment>
        {showVideo ? (
          <VideoWrap positionStatic={positionStatic}>
            {showVideoOverlay && (
              <VideoButtonsOverlay>
                <ExampleButton onClick={() => this.setState({ visible: true })}>
                  See an example
                </ExampleButton>

                <PlayButton onClick={this.playVideo}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    fill="none"
                  >
                    <circle
                      cx="40"
                      cy="40"
                      r="40"
                      fill="#fff"
                      fillOpacity="0.8"
                    ></circle>
                    <path
                      fill="#181818"
                      d="M34.392 30l18 10.392-18 10.393V30z"
                    ></path>
                  </svg>
                </PlayButton>
                <div></div>
              </VideoButtonsOverlay>
            )}
            {caption ? (
              <VideoFrame
                video={url}
                poster={poster}
                videoControls={true}
                track={caption}
              />
            ) : (
              <VideoStyle
                src={url}
                poster={poster}
                autoPlay={autoplay}
                controls
                controlsList="nodownload"
                positionStatic={positionStatic}
                ref={(video) => (this.video = video)}
              />
            )}
          </VideoWrap>
        ) : (
          <Poster onClick={() => this.setState({ showVideo: true })}>
            <img src={poster} alt="Quickpage Video" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              fill="none"
            >
              <circle
                cx="40"
                cy="40"
                r="40"
                fill="#fff"
                fillOpacity="0.8"
              ></circle>
              <path
                fill="#181818"
                d="M34.392 30l18 10.392-18 10.393V30z"
              ></path>
            </svg>
          </Poster>
        )}

        {visible && (
          <ModalExample
            visible={visible}
            closeHandler={() => this.setState({ visible: false })}
            gaTrackLabel={`Hero Video Video`}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Video;
