import React from "react";
import styled from "styled-components";

// Layout
import Layout from "../components/layout";
import Contain from "../components/contain";
import Cta from "../components/cta";
import Seo from "../components/seo";

// Components
import HeroPage from "../components/hero-page";
import Heading from "../components/heading";
import Video from "../components/video";

const TestimonialsLayout = styled.div`
  padding: 0 1rem;
  column-count: 6;
  column-gap: 2rem;

  @media (max-width: 1920px) {
    column-count: 4;
  }

  @media (max-width: 1280px) {
    column-count: 3;
  }

  @media (max-width: 786px) {
    column-count: 2;
  }

  @media (max-width: 640px) {
    column-count: 1;
  }
`;

const Item = styled.div`
  display: inline-block;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  background: #fff;
  padding: 0.5rem;
  margin: 0 0 2rem;

  img {
    display: block;
    border-radius: 1rem;
  }
`;

export default class Testimonials extends React.Component {
  state = {
    slides: [],
  };

  componentDidMount() {
    let rows = [];
    const reLinks =
      "https://quickpage-assets.s3.amazonaws.com/public/marketing/testimonials/re/";
    const reCount = 24; // 1 extra

    for (var i = 1; i < reCount; i++) {
      rows.push({
        type: "image",
        url: `${reLinks}${i}.jpg`,
      });
    }

    const videos = [
      {
        url:
          "https://dburs8kx36lyd.cloudfront.net/media/38159E0B-AF43-4DF3-EA6E-01D004606425/x8dmDiDfrsi2f5xX9.mp4-web.mp4",
        poster:
          "https://dburs8kx36lyd.cloudfront.net/media/38159E0B-AF43-4DF3-EA6E-01D004606425/x8dmDiDfrsi2f5xX9.mp4-poster-00001.png",
        type: "video",
      },
      {
        url:
          "https://dburs8kx36lyd.cloudfront.net/media/906D45B3-4617-404E-C377-0DF6AC8C72AE/z9mPZaxM7saqjzgyx.mp4-web.mp4",
        poster:
          "https://dburs8kx36lyd.cloudfront.net/media/906D45B3-4617-404E-C377-0DF6AC8C72AE/z9mPZaxM7saqjzgyx.mp4-poster-00001.png",
        type: "video",
      },
      {
        url:
          "https://dburs8kx36lyd.cloudfront.net/media/BA58F6EE-4E7A-4B0B-BA4E-B1D8791F5D9C/NKzCewz9AdsEyCjkX.mp4-web.mp4",
        poster:
          "https://dburs8kx36lyd.cloudfront.net/media/BA58F6EE-4E7A-4B0B-BA4E-B1D8791F5D9C/NKzCewz9AdsEyCjkX.mp4-poster-00001.png",
        type: "video",
      },
      {
        url:
          "https://dburs8kx36lyd.cloudfront.net/media/269D7CC5-793E-4157-F7B5-8CDA01E8561A/sMTitrJpkiaPeQEfZ.mp4-web.mp4",
        poster:
          "https://dburs8kx36lyd.cloudfront.net/media/269D7CC5-793E-4157-F7B5-8CDA01E8561A/sMTitrJpkiaPeQEfZ.mp4-poster-00001.png",
        type: "video",
      },
      {
        url:
          "https://dburs8kx36lyd.cloudfront.net/media/5E0ADE58-B8CE-4209-BCEB-0E9F59B0313B/3tiHNtYQanPwCeDqt.mp4-web.mp4",
        poster:
          "https://dburs8kx36lyd.cloudfront.net/media/5E0ADE58-B8CE-4209-BCEB-0E9F59B0313B/3tiHNtYQanPwCeDqt.mp4-poster-00001.png",
        type: "video",
      },
      {
        url:
          "https://dburs8kx36lyd.cloudfront.net/media/FB93DB9B-3292-4596-917A-055FD15D5DA1/nw2tLz7gHoJGhdzr2.mp4-web.mp4",
        poster:
          "https://dburs8kx36lyd.cloudfront.net/media/FB93DB9B-3292-4596-917A-055FD15D5DA1/nw2tLz7gHoJGhdzr2.mp4-poster-00001.png",
        type: "video",
      },
      {
        url:
          "https://dburs8kx36lyd.cloudfront.net/media/EC2A8E2D-2CDF-4456-8A45-F0368E3DFE12/uwNpTEu2d8TNEpDPE.mp4-web.mp4",
        poster:
          "https://dburs8kx36lyd.cloudfront.net/media/2A7806D5-F50E-428E-A282-4DFA80B0FCAA/cjRn3rCpxxy4HdrNx.png",
        type: "video",
      },
    ];

    rows = [...rows, ...videos];

    const shuffled = rows
      .map((a) => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value);

    this.setState({ slides: shuffled });
  }

  render() {
    const { slides } = this.state;

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Testimonials | Quickpage"
          description="Follow up better. Close deals faster. With video."
          keywords="video, email, video email"
          url="https://sutton.quickpage.io/testimonials"
        />
        <HeroPage>
          <Contain>
            <Heading textCenter>Testimonials</Heading>
          </Contain>
        </HeroPage>

        <TestimonialsLayout>
          {slides.map((e, i) => (
            <Item key={`t+${i}`}>
              {e.type === "image" ? (
                <img src={e.url} />
              ) : (
                <Video
                  url={e.url}
                  poster={e.poster}
                  positionStatic={true}
                  autoplay={true}
                />
              )}
            </Item>
          ))}
        </TestimonialsLayout>

        <Cta location={this.props.location} />
      </Layout>
    );
  }
}
